import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, SecurityContext } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { serverErrorInterceptor } from '@lib/interceptors';
import { jwtInterceptor } from '@lib/interceptors/jwt.interceptor';
import { socketConfig } from '@lib/services/socket/socket-config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';
import { SocketIoModule } from 'ngx-socket-io';
import { routes } from './app.routes';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            HttpClientModule,
            SocialLoginModule,
            SocketIoModule.forRoot(socketConfig),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: httpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
            MarkdownModule.forRoot({
                sanitize: SecurityContext.NONE,
            }),
        ),
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(withInterceptors([serverErrorInterceptor, jwtInterceptor])),
    ],
};
