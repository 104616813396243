import { CommonModule } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutWithSidebarComponent } from '@lib/components/layouts/layout-with-sidebar/layout-with-sidebar.component';
import { AuthService, LanguageService, ThemeService } from '@lib/services';
import { SocketService } from '@lib/services/socket';
import { LayoutHorizontalComponent } from './lib/components/layouts/layout-horizontal/layout-horizontal.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterModule, LayoutHorizontalComponent, LayoutWithSidebarComponent],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterContentChecked {
    isAuthenticated$ = inject(AuthService).isAuthenticated$;

    private readonly _themeService = inject(ThemeService);
    private readonly _languageService = inject(LanguageService);
    private readonly _socketService = inject(SocketService);
    private readonly _cdr = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this._themeService.init();
        this._languageService.init();
        this._socketService.init();
    }

    ngAfterContentChecked(): void {
        this._cdr.detectChanges();
    }
}
